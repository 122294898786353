import './App.css';
import Main from './components/Main/Main';

function App() {
  return (
    <div>
      <Main />
    </div>
  );
}
export default App;