import './newsItem.css';

function NewsItem(props) {
  return (
    <div className='article'>
        <div className="text-center title">
            <a href={props.link} target="_blank" rel="noreferrer">{props.title}</a>
        </div>
        <div className="source">{props.source}</div>
        <div className="text-center summary">{props.summary}</div>
        <div className="date">{props.time}</div>
    </div>
  )
}

export default NewsItem