import './main.css';
import News from '../News/News';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid} from '@fortawesome/fontawesome-svg-core/import.macro'
import { useEffect, useState } from 'react';

function Main() {

    const [Articles, setArticles] = useState([]);
    const [offset, setOffset] = useState(0);
    const [search, setSearch] = useState('');
    const [toggle, setToggle] = useState(false);

    const [searchMode, setSearchMode] = useState(false);
    const [onLaunch, setOnLaunch] = useState(true);

    const handleChange = (e) => {
        setSearch(e.target.value) 
        setSearchMode(false)
    }

    const handleSubmit = (e) => {

        e.preventDefault()

        if (search !== '') {
            setArticles([])
            setOffset(0)
            setToggle(!toggle)
            setSearchMode(true)
            setOnLaunch(false)
        }
    }

    useEffect(() => {

        let isFetched = false;

        const loadArticles = () => {
            fetch(`/news?skip=${offset}`)
            .then(response => response.json())
            .then(articles => {
                    if (!isFetched) {
                        setArticles(Articles => Articles.concat(articles)
                        .sort((a, b) => a['date'] <= b['date'] ? 1 : -1))
                    }
            })
        }

        if (!searchMode && onLaunch && search === '') {
            loadArticles()
        }

        return () => {isFetched=true}

    }, [offset, onLaunch, search, searchMode])

    useEffect(() => {

        const loadSearch = () => {
            fetch(`/search?content=${search}&skip=${offset}`)
            .then(response => response.json())
            .then(articles => {
                    setArticles(Articles => Articles.concat(articles)
                    .sort((a, b) => a['date'] <= b['date'] ? 1 : -1))
            })
        }

        if (searchMode && !onLaunch && search !== '') {
            loadSearch()
        }

    }, [offset, onLaunch, search, searchMode, toggle])

    useEffect(() => {
        const handleScroll = (e) => {
                const scrollHeight = e.target.documentElement.scrollHeight
                const currentHeight = e.target.documentElement.scrollTop + window.innerHeight
                if (currentHeight + 1 >= scrollHeight) {
                    setOffset(offset + 1)
                }
        }
        window.addEventListener("scroll", handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [offset])

    return (
        <div className='Main'>

             <form className='search' onSubmit={handleSubmit}>
                <input  
                    className='search__input'
                    type='text'
                    id='search'
                    onChange={handleChange}
                />
                <button className='search__button'>
                    Search <FontAwesomeIcon icon={solid('magnifying-glass')} />
                </button>
            </form>

            <News Articles={Articles} />

        </div>
    )
}

export default Main