import './news.css';
import NewsItem from '../NewsItem/NewsItem';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'
import { v4 as uuidv4 } from 'uuid';

dayjs.extend(relativeTime)

function News(props) {

  return (
        Object.keys(props.Articles).map((article) => {
        
            var title = props.Articles[article].title;
            var summary = props.Articles[article].summary;
            var time = dayjs(props.Articles[article].date*1000).fromNow();
            var link = props.Articles[article].link;
            var source = props.Articles[article].source;

            if (article!=null) {
                    return(
                        <div key={uuidv4()} className="News">
                            <NewsItem title={title} summary={summary} time={time} link={link} source={source} />
                        </div>
                    );
            } else {
                return(
                    <>
                    </>
                );
            }
        })
    )
}

export default News